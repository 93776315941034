@import url("https://fonts.googleapis.com/css2?family=Commissioner:wght@400;500;700;800&display=swap");

@font-face {
  font-family: "Garet";
  src: url(./assets/fonts/Garet-Book.otf);
}

@font-face {
  font-family: "GaretHeavy";
  src: url(./assets/fonts/Garet-Heavy.otf);
}

@font-face {
  font-family: "OddvalText";
  src: url(./assets/fonts/OddvalText-SemiBold.ttf);
  font-weight: 600;
}
